import { useSelector } from 'react-redux';

import { IconFooterCSCenter, IconFooterNotice } from '@/assets/svgs/system';
import { isRidiAppSelector } from '@/features/global/variables/variablesSlice';

import { BusinessInformation } from '../BusinessInformation';
import { FooterMenu } from '../FooterMenu';
import { MediaList } from '../MediaList';
import * as styles from './Footer.styles';

export const FooterCustomerService = ({ className }: { className?: string }): ReactJSX.Element => (
  <ul css={styles.customerServiceListStyle} className={className}>
    <li css={styles.customerServiceItemStyle}>
      <a
        href="https://ridihelp.ridibooks.com/support/home"
        css={styles.customerServiceItemLinkStyle}
        target="_blank"
        title="고객센터"
        rel="noreferrer">
        <IconFooterCSCenter css={styles.customerServiceIconStyle} />
        고객센터
      </a>
    </li>

    <li css={styles.customerServiceItemStyle}>
      <a
        href="https://ridihelp.ridibooks.com/support/solutions/folders/154000698539"
        css={styles.customerServiceItemLinkStyle}
        target="_blank"
        title="공지사항"
        rel="noreferrer">
        <IconFooterNotice css={styles.customerServiceIconStyle} />
        공지사항
      </a>
    </li>
  </ul>
);

export const Footer = ({ className }: { className?: string }): ReactJSX.Element => {
  const isInApp = useSelector(isRidiAppSelector);

  if (isInApp) {
    return <></>;
  }
  return (
    <div css={styles.footerRootStyle} className={className}>
      <hr css={styles.footerBorderStyle} />
      <footer css={styles.footerWrapperStyle}>
        <div css={styles.footerContentsStyle}>
          <div css={styles.footerColumnsStyle}>
            <FooterCustomerService css={styles.footerCustomerServiceStyle} />
            <FooterMenu css={styles.footerMenuStyle} />
          </div>

          <BusinessInformation css={styles.footerBusinessInfoStyle} />
        </div>

        <MediaList css={styles.footerMediaStyle} />
      </footer>
    </div>
  );
};
